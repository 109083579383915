<template>
  <div>
    <validation-observer
      ref="form"
      tag="form"
      class="mt-4"
      @submit.prevent="handleSubmit"
    >
      <v-checkbox
        v-model="form.main"
        label="Главный трек"
        persistent-hint
        class="mb-4"
        hint="Является ли трек главным для этой компетенции"
      ></v-checkbox>

      <v-row>
        <v-col v-if="hasPenaltyOption" md="6" xs="12">
          <validation-provider
            v-slot="{ errors }"
            :rules="{ double: true, required: true }"
            name="penalty"
          >
            <v-text-field
              v-model.number="form.penalty"
              dense
              outlined
              :error-messages="errors"
              label="Пенальти"
              type="number"
              step="0.1"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col md="6" xs="12">
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, double: true, max_value: 1, is_not: 0 }"
            name="weight"
          >
            <v-text-field
              v-model.number="form.weight"
              dense
              outlined
              :error-messages="errors"
              label="Весовой коэффициент трека"
              hint="Введите число меньше 1 но больше 0"
              type="number"
              step="0.1"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <div>
        <p class="mb-0"><strong>Ограничения максимального балла</strong></p>
        <p>
          Число, которым будет ограничен итоговый балл за трек (после применения
          весового коэффициента). 0 - означает, что все участники получат 0
          баллов. Если вы не хотите ограничивать максимальный балл, поставьте
          соответствующую галочку.
        </p>
        <v-row>
          <v-col md="6">
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: !form.no_limit,
                double: true,
                min_value: 0,
              }"
              slim
              name="weight"
            >
              <v-text-field
                v-model.number="form.score_limit"
                dense
                outlined
                :disabled="form.no_limit"
                :error-messages="errors"
                type="number"
                persistent-hint
                label="Максимальный балл"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col md="6">
            <v-checkbox
              v-model="form.no_limit"
              class="mt-1"
              hide-details
              label="Не ограничивать"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <validation-provider
        v-slot="{ errors }"
        :rules="{ min: 3, max: 500 }"
        name="description"
        slim
      >
        <v-textarea
          v-model="form.description"
          rows="5"
          label="Описание"
          :error-messages="errors"
          counter
          dense
          outlined
        ></v-textarea>
      </validation-provider>

      <v-btn color="primary" :loading="pending" type="submit">Сохранить</v-btn>
    </validation-observer>
  </div>
</template>

<script>
import { TRACK_TYPE_EVENT } from "@/constants";
import { apiClient } from "@/api";
/**
 * Форма с общими настройками для трека
 */
export default {
  name: "CommonTrackForm",
  props: {
    track: {
      type: Object,
    },
    competence: {
      type: Object,
    },
  },
  data() {
    return {
      pending: false,
      form: {
        penalty: "",
        weight: "",
        description: "",
        score_limit: "",
        no_limit: false,
        main: false,
      },
    };
  },
  computed: {
    hasPenaltyOption() {
      const track = this.track?.track || {};
      return track.type === TRACK_TYPE_EVENT || track.code === "stepik";
    },
  },
  watch: {
    track: {
      immediate: true,
      deep: true,
      handler(val) {
        this.mapTrackToForm(val);
      },
    },
  },
  methods: {
    mapTrackToForm(track) {
      this.form = {
        penalty: track.penalty,
        weight: track.weight,
        description: track.description,
        score_limit: track.score_limit,
        no_limit: track.score_limit === null,
        main: !!track.main,
      };
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      try {
        const payload = { ...this.form };
        delete payload.no_limit;
        if (this.form.no_limit) {
          payload.score_limit = null;
        }

        const { data } = await apiClient({
          method: "PATCH",
          url: `competences/${this.competence?.id}/tracks/${this.track?.id}`,
          data: payload,
        });
        this.$emit("onUpdateTracks", data);
        this.$toast("Настройки успешно обновлены", { type: "success" });
      } catch (error) {
        this.$toast(`Не удалось сохранить настройки. ${error.message}`);
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
