var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":_vm.maxWidth},on:{"click:outside":_vm.handleClose,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClose.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[(_vm.isEdit)?_c('span',[_vm._v("Изменить настройки для курса "),_c('a',{staticClass:"text-decoration-none",attrs:{"title":"Открыть курс на Степике","target":"_blank","rel":"noopener noreferrer","href":`https://stepik.org/course/${_vm.selectedCourse.course_id}`}},[_vm._v("#"+_vm._s(_vm.selectedCourse.course_id))])]):_c('span',[_vm._v("Добавить крусы на Stepik")])]),_c('v-card-text',{staticClass:"black--text mt-4"},[_c('validation-observer',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(!_vm.isEdit)?_c('validation-provider',{attrs:{"rules":{ required: true, ids: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Список ID курсов","hint":"Укажите id курсов через заяпятую","error-messages":errors,"dense":"","outlined":""},model:{value:(_vm.form.courses_ids),callback:function ($$v) {_vm.$set(_vm.form, "courses_ids", $$v)},expression:"form.courses_ids"}})]}}],null,false,3428608314)}):_vm._e(),_c('validation-provider',{attrs:{"rules":"required","tag":"div"}},[_c('v-select',{attrs:{"items":_vm.stepikTypeOptions,"label":"Укажите тип курса(ов)","dense":"","outlined":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),(_vm.isTestingCources)?_c('validation-provider',{attrs:{"tag":"div","rules":{
            double: true,
            min_value: 0,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","label":"Максимальный балл","outlined":"","error-messages":errors,"type":"number","step":"0.1"},model:{value:(_vm.form.max_score),callback:function ($$v) {_vm.$set(_vm.form, "max_score", _vm._n($$v))},expression:"form.max_score"}})]}}],null,false,435887011)}):_vm._e(),_c('validation-provider',{attrs:{"tag":"div","rules":{
            double: true,
            min_value: 0,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","label":"Базовый балл","outlined":"","error-messages":errors,"type":"number","step":"0.1"},model:{value:(_vm.form.score),callback:function ($$v) {_vm.$set(_vm.form, "score", _vm._n($$v))},expression:"form.score"}})]}}])}),(_vm.isEducationCources)?[_c('validation-provider',{attrs:{"tag":"div","rules":{
              double: true,
              min_value: 0,
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","label":"Коэффициент за обычный сертификат","outlined":"","error-messages":errors,"type":"number","step":"0.1"},model:{value:(_vm.form.regular_cert_coefficient),callback:function ($$v) {_vm.$set(_vm.form, "regular_cert_coefficient", _vm._n($$v))},expression:"form.regular_cert_coefficient"}})]}}],null,false,1704466881)}),_c('validation-provider',{attrs:{"tag":"div","rules":{
              double: true,
              min_value: 0,
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","label":"Коэффициент за сертификат с отличием","outlined":"","error-messages":errors,"type":"number","step":"0.1"},model:{value:(_vm.form.distinct_cert_coefficient),callback:function ($$v) {_vm.$set(_vm.form, "distinct_cert_coefficient", _vm._n($$v))},expression:"form.distinct_cert_coefficient"}})]}}],null,false,612127621)})]:_vm._e()],2),(_vm.error)?_c('div',{staticClass:"my-3 error--text"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('v-card-actions',{staticClass:"px-5 pb-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("Сохранить")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.handleClose}},[_vm._v("Отмена")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }