<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5">Добавить теги</v-card-title>
      <v-card-text class="black--text">
        <p>Укажите какие теги должны учитываться в этом треке</p>
        <div class="mb-3">
          <v-chip
            v-for="tag in list"
            :key="tag.id"
            :color="tag.color"
            class="mr-2 mb-2"
            :outlined="tag.outlined"
            :close="tag.close"
            @click="handleTagClick(tag)"
            @click:close="handleTagClick(tag)"
            >{{ tag.name }}</v-chip
          >
        </div>
        <app-pager
          :value="page"
          :total="total"
          :limit="limit"
          @input="getTags"
        ></app-pager>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <v-btn
          color="primary"
          :disabled="!selectedTags.length"
          @click="handleSubmit"
          >Сохранить</v-btn
        >
        <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_DIALOG_WIDTH } from "@/constants";

import { apiClient } from "@/api";
const initialState = () => {
  return {
    pending: false,
    error: "",
    selectedTags: [],
    page: 1,
    limit: 50,
    total: 0,
    tags: [],
  };
};
export default {
  name: "AddStackOverflowTags",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    currentTags: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    list() {
      const currentIds = this.currentTags.map((n) => n.tag_id);
      return this.tags.map((n) => {
        const disabled = currentIds.includes(n.id);
        const selected = this.selectedTags.includes(n.id);
        return {
          ...n,
          selected,
          disabled,
          color: disabled ? "grey lighten-2" : "primary",
          outlined: selected ? false : disabled ? false : true,
          close: selected,
        };
      });
    },
  },
  watch: {
    value: {
      handler(val) {
        // скидывем форму при закрытии
        if (!val) {
          this.reset();
        } else {
          this.getTags();
        }
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    handleClose() {
      this.$emit("input", false);
    },
    handleTagClick(tag) {
      if (tag.disabled) return;
      const idx = this.selectedTags.indexOf(tag.id);
      if (idx === -1) {
        this.selectedTags.push(tag.id);
      } else {
        this.selectedTags.splice(idx, 1);
      }
    },
    async getTags(page = 1) {
      try {
        const { data } = await apiClient.get("/stackoverflow/tags", {
          params: {
            limit: this.limit,
            offset: this.limit * (page - 1),
          },
        });
        this.total = data.count;
        this.tags = data.Results;
        this.page = page;
      } catch (error) {
        this.$toast(`Не удалось получить список треков. ${error.message}`, {
          type: "error",
        });
      }
      this.pending = false;
    },
    async handleSubmit() {
      this.$emit("updateTags", this.selectedTags);
      this.handleClose();
    },
  },
};
</script>

<style></style>
