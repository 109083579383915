<template>
  <div>
    <h3 class="text-h5 mb-3"><strong>Список тегов трека</strong></h3>
    <v-alert
      v-if="!tags.length && !pending && page === 1"
      color="red"
      dense
      type="error"
    >
      Добавьте теги Stackoverflow
    </v-alert>
    <v-chip
      v-for="tag in tags"
      :key="tag.id"
      color="primary"
      outlined
      close
      class="mr-2 mb-2"
      @click:close="handleDeleteTag(tag.id)"
      >{{ tag.tag.name }}</v-chip
    >

    <app-pager
      :value="page"
      :total="total"
      :limit="limit"
      @input="getTags"
    ></app-pager>
    <div class="mt-3">
      <v-btn color="primary" @click="tagsDialog = true">Добавить теги</v-btn>
    </div>
    <add-stack-overflow-tags
      v-model="tagsDialog"
      :current-tags="tags"
      @updateTags="handleAddTags"
    />
  </div>
</template>

<script>
import { apiClient } from "@/api";
import AddStackOverflowTags from "@/components/dialogs/AddStackOverflowTags";
export default {
  name: "StackoverflowSection",
  components: {
    AddStackOverflowTags,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      page: 1,
      total: 0,
      limit: 20,
      tags: [],
      tagsDialog: false,
    };
  },
  created() {
    this.getTags();
  },
  methods: {
    // async getTags() {
    //   const { data } = await apiClient.get("/stackoverflow/tags");
    //   console.log("data", data);
    // },
    async getTags(page = 1) {
      this.pending = true;
      try {
        const { data } = await apiClient.get(
          `/tracks/${this.track.id}/settings/stackoverflow/tags`,
          {
            params: {
              limit: this.limit,
              offset: (page - 1) * this.limit,
            },
          }
        );
        this.total = data.count;
        this.tags = data.results;
        this.page = page;
      } catch (error) {
        this.$toast(
          `Не удалось получить список тегов трека. ${error.message}`,
          { type: "error" }
        );
      }
      this.pending = false;
    },
    async handleAddTags(ids) {
      try {
        await apiClient({
          method: "POST",
          url: `/tracks/${this.track.id}/settings/stackoverflow/tags`,
          data: {
            tags_ids: ids,
          },
        });
        this.getTags(this.page);
      } catch (error) {
        this.$toast(`Не удалось удалить тег. ${error.message}`, {
          type: "error",
        });
      }
    },
    async handleDeleteTag(setting_id) {
      try {
        await apiClient.delete(
          `/tracks/${this.track.id}/settings/stackoverflow/tags/${setting_id}`
        );
        this.getTags(this.page);
      } catch (error) {
        this.$toast(`Не удалось добавить теги. ${error.message}`, {
          type: "error",
        });
      }
    },
  },
};
</script>

<style></style>
