<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5">
        <span v-if="isEdit"
          >Изменить настройки для курса
          <a
            title="Открыть курс на Степике"
            target="_blank"
            rel="noopener noreferrer"
            class="text-decoration-none"
            :href="`https://stepik.org/course/${selectedCourse.course_id}`"
            >#{{ selectedCourse.course_id }}</a
          ></span
        >
        <span v-else>Добавить крусы на Stepik</span>
      </v-card-title>
      <v-card-text class="black--text mt-4">
        <validation-observer
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit"
        >
          <validation-provider
            v-if="!isEdit"
            v-slot="{ errors }"
            :rules="{ required: true, ids: true }"
            tag="div"
          >
            <v-text-field
              v-model="form.courses_ids"
              label="Список ID курсов"
              hint="Укажите id курсов через заяпятую"
              :error-messages="errors"
              dense
              outlined
            ></v-text-field>
          </validation-provider>

          <validation-provider rules="required" tag="div">
            <v-select
              v-model="form.type"
              :items="stepikTypeOptions"
              label="Укажите тип курса(ов)"
              dense
              outlined
            >
            </v-select>
          </validation-provider>

          <!-- <v-datetime-picker
            v-model="form.start"
            label="Дата начала"
            :text-field-props="{
              hint: `Дата начала (влияет на расчет old_penalty). Если не задать, то old_penalty рассчитан не будет`,
              'persistent-hint': true,
              outlined: true,
              dense: true,
            }"
            :time-picker-props="{
              format: '24hr',
            }"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-timer</v-icon>
            </template>
          </v-datetime-picker>

          <v-datetime-picker
            v-model="form.end"
            label="Дата окончания"
            :text-field-props="{
              hint: `Дата окончания (ни на что не влияет)`,
              'persistent-hint': true,
              outlined: true,
              dense: true,
            }"
            :time-picker-props="{
              format: '24hr',
            }"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-timer</v-icon>
            </template>
          </v-datetime-picker> -->

          <validation-provider
            v-if="isTestingCources"
            v-slot="{ errors }"
            tag="div"
            :rules="{
              double: true,
              min_value: 0,
              required: true,
            }"
          >
            <v-text-field
              v-model.number="form.max_score"
              dense
              label="Максимальный балл"
              outlined
              :error-messages="errors"
              type="number"
              step="0.1"
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            tag="div"
            :rules="{
              double: true,
              min_value: 0,
              required: true,
            }"
          >
            <v-text-field
              v-model.number="form.score"
              dense
              label="Базовый балл"
              outlined
              :error-messages="errors"
              type="number"
              step="0.1"
            ></v-text-field>
          </validation-provider>

          <template v-if="isEducationCources">
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              :rules="{
                double: true,
                min_value: 0,
                required: true,
              }"
            >
              <v-text-field
                v-model.number="form.regular_cert_coefficient"
                dense
                label="Коэффициент за обычный сертификат"
                outlined
                :error-messages="errors"
                type="number"
                step="0.1"
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              tag="div"
              :rules="{
                double: true,
                min_value: 0,
                required: true,
              }"
            >
              <v-text-field
                v-model.number="form.distinct_cert_coefficient"
                dense
                label="Коэффициент за сертификат с отличием"
                outlined
                :error-messages="errors"
                type="number"
                step="0.1"
              ></v-text-field>
            </validation-provider>
          </template>
        </validation-observer>

        <div v-if="error" class="my-3 error--text">{{ error }}</div>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <v-btn color="primary" @click="handleSubmit">Сохранить</v-btn>
        <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_DIALOG_WIDTH } from "@/constants";
import { STEPIK_TYPE_TESTING, STEPIK_TYPE_EDUCATION } from "@/constants";
import { apiClient } from "@/api";
import { pathJoin } from "@/api/utils";

const initialForm = () => {
  return {
    max_score: "",
    regular_cert_coefficient: "",
    score: "",
    type: STEPIK_TYPE_TESTING,
    courses_ids: "",
    distinct_cert_coefficient: "",
  };
};

export default {
  name: "StepikCourseDialog",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    track: Object,
    selectedCourse: Object,
  },
  data() {
    return {
      form: initialForm(),
      error: "",
    };
  },
  computed: {
    isEdit() {
      return !!this.selectedCourse?.id;
    },
    isEducationCources() {
      return this.form.type === STEPIK_TYPE_EDUCATION;
    },
    isTestingCources() {
      return this.form.type === STEPIK_TYPE_TESTING;
    },
    stepikTypeOptions() {
      return [
        {
          text: "Образовательные курсы",
          value: STEPIK_TYPE_EDUCATION,
          hint: "Расчет ведется за полученные сертификаты",
        },
        {
          text: "Курсы Тестирования",
          value: STEPIK_TYPE_TESTING,
          hint: "Расчет ведется по баллам",
        },
      ];
    },
  },
  watch: {
    value: {
      handler(val) {
        // скидывем форму при закрытии
        if (!val) {
          this.reset();
        } else {
          this.mapCourseToForm();
        }
      },
    },
  },
  methods: {
    reset() {
      this.error = "";
      this.form = initialForm();
      this.$refs.form.reset();
    },
    handleClose() {
      this.$emit("input", false);
    },
    async handleSubmit() {
      const { isEducationCources, isEdit, selectedCourse } = this;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const payload = { ...this.form };
      const hasChangedType = isEdit && selectedCourse.type !== payload.type;

      if (isEducationCources) {
        // Если поменялся тип курса, то нужно занулить значения полей от прошлого типа
        if (hasChangedType) {
          payload.max_score = null;
        } else {
          delete payload.max_score;
        }
      } else {
        // Если поменялся тип курса, то нужно занулить значения полей от прошлого типа
        if (hasChangedType) {
          payload.distinct_cert_coefficient = null;
          payload.regular_cert_coefficient = null;
          payload.score = null;
        } else {
          delete payload.distinct_cert_coefficient;
          delete payload.regular_cert_coefficient;
        }
      }
      if (!isEdit) {
        payload.courses_ids = payload.courses_ids.split(",").map(Number);
      }

      const config = {
        method: "POST",
        url: `/tracks/${this.track?.id}/settings/stepik`,
        data: payload,
      };

      if (isEdit) {
        config.method = "PATCH";
        config.url = pathJoin(config.url, this.selectedCourse.id);
      }

      try {
        await apiClient(config);
        this.$emit("courseUpdate");
        this.handleClose();
      } catch (error) {
        this.error = `Не удалось ${isEdit ? "обновить" : "добавить"} курс(ы). ${
          error.message
        }`;
      }
    },
    mapCourseToForm() {
      const { selectedCourse } = this;
      if (!selectedCourse?.id) return;
      this.form = Object.keys(this.form).reduce((result, key) => {
        if (key in selectedCourse) {
          result[key] = selectedCourse[key];
        } else {
          result[key] = this.form[key];
        }
        return result;
      }, {});
    },
  },
};
</script>

<style></style>
