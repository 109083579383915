<template>
  <div>
    <v-divider class="my-6"></v-divider>
    <h3 class="text-h5 mb-3"><strong>Курсы на платформе Stepik</strong></h3>
    <v-alert
      v-if="!courses.length && !pending"
      color="red"
      dense
      type="error"
      @click="openCourseDialog(null)"
    >
      Добавьте курсы Stepik
    </v-alert>

    <v-btn color="primary" class="mb-3" @click="openCourseDialog(null)"
      >Добавить курсы</v-btn
    >
    <v-simple-table v-if="courses.length" class="mb-3">
      <template #default>
        <thead>
          <tr>
            <th>ID Курса</th>
            <th>Тип</th>
            <th title="Только для типа Testing">Макс. балл</th>
            <th title="Только для типа Education">Балл</th>
            <th title="Коэффициенты сертификатов. Только для типа Education">
              Серт. коэф
            </th>
            <th
              title="Коэффициенты сертификатов с отличием. Только для типа Education"
            >
              Отл.Серт. коэф
            </th>
            <th width="140px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedCourses" :key="item.id">
            <td>
              <a
                title="Открыть курс на Степике"
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none"
                :href="`https://stepik.org/course/${item.course_id}`"
                ><v-icon left small>mdi-open-in-new</v-icon>{{ item.course_id }}
              </a>
            </td>
            <td>
              <span v-if="item.type === STEPIK_TYPE_EDUCATION"
                >Образовательный курс</span
              >
              <span v-else-if="item.type === STEPIK_TYPE_TESTING"
                >Тестирование</span
              >
              <span v-else class="error--text">Не указан тип!</span>
            </td>
            <td>
              <v-icon
                v-if="item.type === STEPIK_TYPE_EDUCATION"
                title="Не применимо для данного типа курсов"
                small
                color="grey lighten-2"
                >mdi-lock</v-icon
              >
              <span v-else> {{ item.max_score }}</span>
            </td>
            <td>
              {{ item.score }}
            </td>
            <td>
              <div v-if="item.type === STEPIK_TYPE_EDUCATION">
                <div title="Коэффициент за обычный сертификат">
                  <v-icon color="blue-grey lighten-3" left small
                    >mdi-seal</v-icon
                  >{{ item.regular_cert_coefficient }}
                </div>
              </div>
              <v-icon
                v-else
                small
                color="grey lighten-2"
                title="Не применимо для данного типа курсов"
                >mdi-lock</v-icon
              >
            </td>
            <td>
              <div v-if="item.type === STEPIK_TYPE_EDUCATION">
                <div title="Коэффициент за сертификат c отличием">
                  <v-icon color="amber lighten-1" left small>mdi-seal</v-icon
                  >{{ item.distinct_cert_coefficient }}
                </div>
              </div>
              <v-icon
                v-else
                small
                color="grey lighten-2"
                title="Не применимо для данного типа курсов"
                >mdi-lock</v-icon
              >
            </td>
            <td class="text-right">
              <v-btn
                title="Редактировать курс"
                icon
                @click="openCourseDialog(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn title="Удалить курс" icon @click="handleDelete(item)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <app-pager
      :value="page"
      :total="total"
      :limit="limit"
      @input="getCourses"
    ></app-pager>

    <div v-if="error" class="my-3 error--text">
      Не удалось получить список курсов. {{ error }}
    </div>

    <stepik-course-dialog
      v-model="courseDialog"
      :track="track"
      :selected-course="selectedCourse"
      @courseUpdate="getCourses(page)"
    ></stepik-course-dialog>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import StepikCourseDialog from "@/components/dialogs/StepikCourseDialog";
import { STEPIK_TYPE_EDUCATION, STEPIK_TYPE_TESTING } from "@/constants";

export default {
  name: "StepikSection",
  components: {
    StepikCourseDialog,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      courses: [],
      courseDialog: false,
      page: 1,
      limit: 20,
      total: 0,
      pending: true,
      error: "",
      STEPIK_TYPE_EDUCATION,
      STEPIK_TYPE_TESTING,
      selectedCourse: null,
    };
  },
  computed: {
    sortedCourses() {
      return [...this.courses].sort((a, b) => {
        return a.course_id > b.course_id ? 1 : -1;
      });
    },
  },
  created() {
    this.getCourses();
  },
  methods: {
    async getCourses(page = 1) {
      try {
        this.pending = true;
        const { data } = await apiClient({
          method: "GET",
          url: `tracks/${this.track?.id}/settings/stepik`,
          params: {
            limit: this.limit,
            offset: (page - 1) * this.limit,
          },
        });
        this.page = page;
        this.total = data.count;
        this.courses = data.results;
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    async handleDelete(item) {
      try {
        const confirm = await this.$root.$confirm(
          "Удалить Курс",
          "Вы уверены, что хотите удалить этот курс со всеми настройками из компетенции?",
          {
            confirmText: "Да, удалить",
            rejectText: "Нет, я передумал",
          }
        );
        if (!confirm) return;
        await apiClient.delete(
          `/tracks/${this.track?.id}/settings/stepik/${item.id}`
        );
        this.$toast(`Курс Stepik #${item.course_id} удален из трека`, {
          type: "success",
        });
        const page =
          this.page > 1 && this.courses.length === 1
            ? this.page - 1
            : this.page;
        this.getCourses(page);
      } catch (error) {
        this.$toast(
          `Не удалось удалить крус Stepik #${item.course_id}. ${error.message}`,
          {
            type: "error",
          }
        );
      }
    },
    async openCourseDialog(course) {
      if (course?.id) {
        this.selectedCourse = {
          ...course,
        };
      } else {
        this.selectedCourse = null;
      }
      this.courseDialog = true;
    },
  },
};
</script>

<style></style>
