<template>
  <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
    <h3 class="text-h5 mb-3"><strong>Коэффициент устаревания</strong></h3>
    <p>
      Значение для следующего года, должно быть меньше или равно предыдущему
    </p>
    <v-alert v-if="!penalty.id && !pending" color="red" dense type="error">
      Установите коэффициенты устаревания
    </v-alert>
    <div style="min-height: 6px">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        :active="pending"
      ></v-progress-linear>
    </div>
    <v-simple-table>
      <template #default>
        <tbody>
          <tr>
            <td>Срок</td>
            <td v-for="opt in options" :key="opt.value">
              {{ opt.text }}
            </td>
          </tr>
          <tr>
            <td>Коэффициент</td>
            <td v-for="(opt, idx) in options" :key="opt.value" class="py-3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  double: true,
                  max_value: idx ? form[options[idx - 1].value] : 1000,
                }"
                :name="`#${idx + 1}`"
              >
                <v-text-field
                  v-model.number="form[opt.value]"
                  dense
                  outlined
                  :error-messages="errors"
                  type="number"
                  step="0.1"
                ></v-text-field>
              </validation-provider>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn color="primary" :loading="pending" type="submit" class="mt-3"
      >Сохранить</v-btn
    >
  </validation-observer>
</template>

<script>
export default {
  name: "OldPenaltyForm",
  props: {
    pending: Boolean,
    penalty: Object,
  },
  data() {
    return {
      form: {
        five: "",
        four: "",
        one: "",
        other: "",
        three: "",
        two: "",
        id: "",
      },
    };
  },
  computed: {
    options() {
      return [
        {
          value: "one",
          text: "1 год",
        },
        {
          value: "two",
          text: "2 года",
        },
        {
          value: "three",
          text: "3 года",
        },
        {
          value: "four",
          text: "4 года",
        },
        {
          value: "five",
          text: "5 лет",
        },
        {
          value: "other",
          text: "Более 5 лет",
        },
      ];
    },
  },
  watch: {
    penalty: {
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          this.mapPenalty();
        }
      },
    },
  },
  methods: {
    mapPenalty() {
      const { penalty } = this;
      if (penalty && typeof penalty === "object") {
        this.form = {
          ...this.form,
          ...penalty,
        };
      }
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid || this.pending) return;
      this.$emit("onSubmit", this.form);
    },
  },
};
</script>

<style></style>
