<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5">{{
        isEdit ? "Изменить настройки мероприятия" : "Добавить мероприятия"
      }}</v-card-title>
      <v-card-text class="black--text">
        <validation-observer ref="form" tag="form" @submit.prevent>
          <p class="text-body-1">
            <strong>Введите ID мероприятий, через запятую</strong>
          </p>
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required: true,
              ids: true,
            }"
            name="events_ids"
            vid="events_ids"
          >
            <v-text-field
              v-model="eventIds"
              :disabled="isEdit"
              dense
              outlined
              :error-messages="errors"
              label="ID мероприятий"
              :hint="`Целочисленные значения разделенные запятыми, максимальное количество ${MAX_IDS_LENGTH}`"
              persistent-hint
            ></v-text-field>
          </validation-provider>
          <template v-if="isTestingTrack">
            <div>
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true, double: true }"
                slim
              >
                <v-text-field
                  v-model.number="maxScore"
                  dense
                  outlined
                  label="Максимальный балл"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </div>
          </template>
          <div>
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true, double: true }"
              slim
            >
              <v-text-field
                v-model.number="score"
                dense
                outlined
                label="Базовый балл"
                type="number"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </div>
          <template v-if="isEdit">
            <v-btn
              color="primary"
              :loading="pending"
              @click="handleUpdateSetting"
              >Сохранить</v-btn
            >
          </template>
          <v-divider class="my-3"></v-divider>
          <div>
            <div class="text-body-1">
              <p class="mb-1"><strong>Укажите коэффициенты ролей</strong></p>
              <p v-if="!isEdit">
                Если возникнет необходимость, вы&nbsp;сможете поменять
                их&nbsp;для каждого отдельного мероприятия.
              </p>

              <p>
                Для всех других ролей достижений, которые не будут добавлены в
                этих настройках, будет применяться
                <strong class="error--text"
                  >коэффициент по умолчанию = 1.</strong
                >
                Таким образом, баллы за достижение будут равны заданному
                <strong>базовому баллу</strong> за мероприятие
                <span v-if="score"> ({{ score }})</span>
              </p>
            </div>

            <v-simple-table
              v-if="roleSheet.length || eventsDefaultsOutcomes.length"
              class="mb-3 role-table"
            >
              <template #default>
                <thead>
                  <tr class="grey lighten-3">
                    <th>Роль достижения</th>
                    <th>Коэффициент</th>
                    <th width="64"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(role, idx) in roleSheet"
                    :key="role.id"
                    class="exist"
                  >
                    <td>
                      <div>{{ role.name }}</div>
                    </td>
                    <td class="py-3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Коэффициент"
                        :rules="{
                          required: true,
                          double: true,
                          min_value: 0,
                        }"
                      >
                        <v-text-field
                          :value="outcomes[idx].coefficient"
                          dense
                          outlined
                          type="number"
                          style="width: 120px"
                          :error-messages="errors"
                          hide-details
                          @change="(v) => handleSetOutcome(role.id, v)"
                        ></v-text-field>

                        <div v-if="errors && errors.length" class="error--text">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="error"
                        title="Удалить коэффициент"
                        outlined
                        @click="handleDeleteOutcome(role)"
                        ><v-icon>mdi-trash-can</v-icon></v-btn
                      >
                    </td>
                  </tr>

                  <tr
                    v-for="role in eventsDefaultsOutcomes"
                    :key="role.id"
                    class="pseudo"
                  >
                    <td>
                      <div>{{ role.name }}</div>
                    </td>
                    <td class="py-3">
                      <v-text-field
                        :value="role.coefficient"
                        dense
                        outlined
                        type="number"
                        style="width: 120px"
                        disabled
                        hide-details
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="primary"
                        title="Создать коэффициент"
                        outlined
                        @click="handleCreateFromDefaultOutcome(role)"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </validation-observer>

        <validation-observer
          ref="createForm"
          tag="form"
          class="mt-6"
          @submit.prevent="handleAddOutcome"
        >
          <v-row>
            <v-col md="6">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-autocomplete
                  v-model="selectedRole"
                  label="Роль"
                  :items="rolesOptions"
                  outlined
                  :error-messages="errors"
                  dense
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col md="3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  double: true,
                  min_value: 0,
                }"
                slim
                name="Коэфф."
              >
                <v-text-field
                  v-model.number="selectedScore"
                  dense
                  type="number"
                  outlined
                  step="0.05"
                  label="Коэффициент"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>

            <v-col md="3">
              <v-btn block type="submit" dark color="primary">Создать</v-btn>
            </v-col>
          </v-row>
        </validation-observer>

        <div v-if="error" class="error--text my-3">{{ error }}</div>
        <v-divider class="my-3"></v-divider>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <template v-if="!isEdit">
          <v-btn color="primary" :loading="pending" @click="handleCreateSetting"
            >Сохранить</v-btn
          >
          <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
        </template>
        <v-btn v-else color="primary" outlined @click="handleClose"
          >Закрыть</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DEFAULT_DIALOG_WIDTH,
  TRACK_CODES,
  DEFAULT_OUTCOME_COEF,
} from "@/constants";
import { apiClient } from "@/api";

const initialData = () => {
  return {
    pending: false,
    error: "",
    eventIds: "",
    outcomes: [],
    selectedRole: null,
    score: "",
    maxScore: "",
    selectedScore: "",
  };
};
const MAX_IDS_LENGTH = 30;
export default {
  name: "EventSettingDialog",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    track: {
      type: Object,
    },
    currentSetting: {
      type: Object,
    },
    eventId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      ...initialData(),
      MAX_IDS_LENGTH,
    };
  },
  computed: {
    allRoles() {
      return this.$store.state.talent.achievementRoles;
    },
    isEdit() {
      return !!this.currentSetting?.id;
    },
    rolesOptions() {
      // Опции для выбора дополнительных коэфициентов
      // должны исключать те роли, что уже настроены
      // Базовые роли должны быть сверху, остальные по алфавиту вниз
      const selectedRoles = this.outcomes.map((n) => n.role_id);
      const baseRoles = this.$store.getters["talent/baseAchievementsRoles"];
      const sortedNotBaseRoles = this.allRoles
        .filter((r) => !baseRoles.includes((br) => br.id === r.id))
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
      return [...baseRoles, ...sortedNotBaseRoles]
        .filter((role) => !selectedRoles.includes(role.id))
        .map((role) => ({
          text: role.name,
          value: role.id,
        }));
    },
    roleSheet() {
      const rolesDict = this.allRoles.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {});
      return this.outcomes.map((outcome) => {
        return {
          ...rolesDict[outcome.role_id],
          outcome_id: outcome.id,
        };
      });
    },
    isTestingTrack() {
      return this.track?.track?.code === TRACK_CODES.TESTING;
    },

    eventsDefaultsOutcomes() {
      const { currentSetting, isEdit } = this;
      const event = this.$store.state.talent.events[currentSetting?.event_id];
      let roles = [];
      if (isEdit && event?.achievement_roles) {
        roles = event.achievement_roles;
      } else if (!isEdit) {
        roles = this.$store.getters["talent/baseAchievementsRoles"];
      }
      const currentIds = this.outcomes.map((n) => n.role_id);
      if (!roles.length) return [];
      return roles.reduce((acc, value) => {
        if (currentIds.includes(value.id)) return acc;
        return [
          ...acc,
          {
            role_id: value.id,
            coefficient: DEFAULT_OUTCOME_COEF,
            id: `new-${value.id}`,
            name: value.name,
          },
        ];
      }, []);
    },
  },
  watch: {
    value(val) {
      if (val) {
        Object.assign(this.$data, initialData());
        if (this.currentSetting) {
          this.mapSetting();
        } else if (this.eventId) {
          this.eventIds = `${this.eventId}`;
          this.createDefaultOutcomes();
        }
      }
      this.$refs.form?.reset();
      this.$refs.createForm?.reset();
    },
  },
  methods: {
    async handleUpdateSetting() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      const { outcomes, score, maxScore, isTestingTrack, currentSetting } =
        this;
      if (!outcomes.length) {
        this.error = "Необходимо задать параметры коэффициентов ролей";
        return;
      }
      try {
        await apiClient({
          method: "PATCH",
          url: `/tracks/${this.track.id}/settings/events/${currentSetting.id}`,
          data: {
            score,
            ...(isTestingTrack && { max_score: maxScore }),
          },
        });
        this.$emit("updateSetting");
        this.handleClose();
      } catch (error) {
        this.$toast(`Не удалось обновить настройки. ${error.message}`, {
          type: "error",
        });
      }
      this.pending = false;
    },
    async handleCreateSetting() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const { outcomes, eventIds, score, maxScore, isTestingTrack } = this;
      if (!outcomes.length) {
        this.error = "Необходимо задать параметры коэффициентов ролей";
        return;
      }
      this.pending = true;
      const events = [...new Set(eventIds.split(","))].map(Number);
      try {
        await apiClient({
          method: "POST",
          url: `/tracks/${this.track.id}/settings/events`,
          data: {
            events_ids: events,
            outcome: outcomes,
            score,
            ...(isTestingTrack && { max_score: maxScore }),
          },
        });

        this.$emit("addSetting");
        this.handleClose();
      } catch (error) {
        if (error.field === "events_ids") {
          this.$refs.form.setErrors({
            events_ids: [error.message],
          });
        } else {
          this.error = error.message;
        }
      }
      this.pending = false;
    },
    mapSetting() {
      const { max_score, outcome, score, event_id } = this.currentSetting;
      if (event_id) {
        this.eventIds = `${event_id}`;
      }
      this.score = score;
      this.maxScore = max_score;
      this.outcomes = outcome.map((n) => {
        return {
          coefficient: n.coefficient,
          id: n.id,
          role_id: n.role_id,
        };
      });
    },
    handleClose() {
      this.$emit("input", false);
    },
    async handleDeleteOutcome(role) {
      const { outcomes } = this;
      if (this.isEdit) {
        const confirm = await this.$root.$confirm(
          "Удалить коэффициент",
          "Вы уверены что хотите удалить этот коэффициент?",
          {
            confirmText: "Да, удалить",
            rejectText: "Нет, отмена",
            confirmColor: "error",
          }
        );
        if (!confirm) return;
        try {
          await apiClient.delete(
            `tracks/${this.track.id}/settings/events/${this.currentSetting.id}/outcome/${role.outcome_id}`
          );
          this.$toast("Коэффициент удален", {
            type: "success",
          });
          this.$emit("updateSetting");
        } catch (error) {
          this.$toast(`Не удалось удалить коэффициент роли. ${error.message}`, {
            type: "error",
          });
          return;
        }
      }
      const idx = outcomes.findIndex((n) => n.role_id === role.id);
      this.$delete(outcomes, idx);
    },
    async handleSetOutcome(roleId, value) {
      const { outcomes } = this;
      const idx = outcomes.findIndex((n) => n.role_id === roleId);
      if (idx >= 0) {
        const payload = {
          ...outcomes[idx],
          coefficient: Number(value),
        };
        this.$set(outcomes, idx, payload);
        if (this.isEdit) {
          try {
            await apiClient({
              method: "PATCH",
              url: `tracks/${this.track.id}/settings/events/${this.currentSetting.id}/outcome/${payload.id}`,
              data: {
                coefficient: payload.coefficient,
              },
            });
            this.$emit("updateSetting");
            this.$toast("Коэффициент роли успешно обновлен", {
              type: "success",
            });
          } catch (error) {
            this.$toast(
              `Не удалось обновить коэффициент роли. ${error.message}`,
              {
                type: "error",
              }
            );
          }
        }
      }
    },
    resetOutcomeForm() {
      this.selectedRole = null;
      this.selectedScore = "";
      this.$refs.createForm.reset();
    },
    async createOutcome(payload) {
      const { data } = await apiClient({
        method: "POST",
        url: `tracks/${this.track.id}/settings/events/${this.currentSetting.id}/outcome`,
        data: {
          ...payload,
        },
      });
      this.$toast("Коэффициент роли добавлен к настройкам мероприятия", {
        type: "success",
      });
      this.$emit("updateSetting");
      return data;
    },
    async handleAddOutcome() {
      const isValid = await this.$refs.createForm.validate();
      if (!isValid) return;
      const outcome = {
        role_id: this.selectedRole,
        coefficient: this.selectedScore,
      };
      // В режиме редактирования, сохраняем сразу аутком
      if (this.isEdit) {
        try {
          const data = await this.createOutcome(outcome);
          outcome.id = data.id;
        } catch (error) {
          this.$toast(
            `Не удалось добавить коэффициент роли. ${error.message}`,
            {
              type: "error",
            }
          );
          this.resetOutcomeForm();
          return;
        }
      }
      this.outcomes.push(outcome);
      this.resetOutcomeForm();
    },
    async createDefaultOutcomes() {
      if (!this.currentSetting?.event_id) return;
      try {
        await this.$store.dispatch("talent/getEventsByIds", [
          this.currentSetting.event_id,
        ]);
      } catch (error) {
        // exit
      }
    },

    async handleCreateFromDefaultOutcome(role) {
      const payload = {
        coefficient: role.coefficient,
        role_id: role.role_id,
      };
      if (this.isEdit) {
        try {
          const data = await this.createOutcome(payload);
          this.outcomes.push({
            ...payload,
            id: data.id,
          });
        } catch (error) {
          this.$toast(
            `Не удалось добавить коэффициент роли. ${error.message}`,
            {
              type: "error",
            }
          );
        }
      } else {
        this.outcomes.push({
          ...payload,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.role-table::v-deep table {
  border-spacing: 0px 10px !important;
  border-collapse: separate;
}
.pseudo {
  td {
    border-style: dotted;
  }
}
.exist {
  td {
    border-style: solid;
  }
}

.pseudo,
.exist {
  td {
    border-width: 1px;
    border-color: transparent;
    border-top-color: #c0c0c0 !important;
    border-bottom-color: #c0c0c0 !important;
  }
  td:first-child {
    border-left-color: #c0c0c0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  td:last-child {
    border-right-color: #c0c0c0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.exist {
  td {
    border-top-color: var(--v-primary-base) !important;
    border-bottom-color: var(--v-primary-base) !important;
  }
  td:first-child {
    border-left-color: var(--v-primary-base) !important;
  }
  td:last-child {
    border-right-color: var(--v-primary-base) !important;
  }
}
</style>
