<template>
  <div>
    <v-row class="mt-3">
      <v-col md="3">
        <v-card>
          <v-list-item
            link
            exact-path
            :to="{
              name: 'competence_tracks',
              params: {
                id: $route.params.id,
              },
            }"
          >
            <v-list-item-icon class="mr-2">
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Все треки</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in currentTracks"
            :key="item.id"
            link
            exact-path
            :to="{
              name: 'competence_track',
              params: { track_id: item.id },
            }"
          >
            <v-list-item-icon v-if="item.main" class="mr-2">
              <v-icon color="primary">mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.track.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-btn
            color="primary"
            block
            @click.prevent="$emit('onOpenAssignDialog')"
            >Добавить трек</v-btn
          >
        </v-card>
      </v-col>
      <v-col md="9">
        <div>
          <div v-if="track">
            <h2 class="text-h5">
              <strong
                >Настройки трека &laquo;{{ track.track.name }}&raquo;</strong
              >
            </h2>
            <v-chip v-if="track.main" color="blue" dark small
              >Главный трек компетенции</v-chip
            >
            <v-divider class="my-6"></v-divider>
            <common-track-form
              :track="track"
              :competence="competence"
              @onUpdateTracks="$emit('onUpdateTracks')"
            />
            <v-divider class="my-6"></v-divider>

            <!-- настройки для треков-мероприятия -->
            <template v-if="settingComponent">
              <component
                :is="settingComponent"
                :key="$route.params.track_id"
                :track="track"
              />
              <v-divider class="my-6"></v-divider>
            </template>

            <v-sheet class="pa-4">
              <p class="error--text"><strong>Удаление трека</strong></p>
              <p>
                Если вы&nbsp;хотите удалить этот трек из компетенции
                &laquo;<strong>{{ competence.name }}</strong
                >&raquo;, введите его название в&nbsp;поле ниже и&nbsp;нажмите
                кнопку &laquo;Удалить&raquo;.
                <br />
                Это действие необратимо. Все настройки трека будут удалены.
              </p>

              <validation-observer
                ref="deleteConfirm"
                :key="$route.params.track_id"
                tag="form"
                @submit.prevent="handleDeleteTrack"
              >
                <div class="d-flex">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      is: track.track.name,
                    }"
                    tag="div"
                    class="flex-grow-1 mr-3"
                  >
                    <v-text-field
                      v-model="nameConfirm"
                      label="Введите название трека"
                      :error-messages="errors"
                      autocomplete="new-password"
                      dense
                      outlined
                    ></v-text-field>
                  </validation-provider>
                  <v-btn color="error" type="submit" class="flex-grow-0"
                    >Удалить трек</v-btn
                  >
                </div>
              </validation-observer>
            </v-sheet>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import CommonTrackForm from "@/components/CommonTrackForm";
import { TRACK_CODES, TRACK_TYPE_EVENT } from "@/constants";
import EventSection from "@/components/settings/EventSection";
import StepikSection from "@/components/settings/StepikSection";
import GitSection from "@/components/settings/GitSection";
import StackoverflowSection from "@/components/settings/StackoverflowSection";
export default {
  name: "Track",
  components: {
    CommonTrackForm,
    EventSection,
    StepikSection,
    GitSection,
    StackoverflowSection,
  },
  props: {
    currentTracks: {
      type: Array,
    },
    competence: {
      type: Object,
    },
  },
  data() {
    return {
      nameConfirm: "",
      deletePending: false,
    };
  },
  metaInfo() {
    return {
      title: `Настройки трека «${this.track?.track?.name}» для компетенции «${this.competence?.name}»`,
    };
  },
  computed: {
    track() {
      return this.currentTracks.find(
        (n) => n.id === +this.$route.params.track_id
      );
    },
    settingComponent() {
      if (!this.track?.track) return undefined;
      const { track } = this.track;
      if (track?.type === TRACK_TYPE_EVENT) return "EventSection";
      if (track?.code === TRACK_CODES.GIT_REPO) return "GitSection";
      if (track?.code === TRACK_CODES.STEPIK) return "StepikSection";
      if (track?.code === TRACK_CODES.STACKOVERFLOW)
        return "StackoverflowSection";
      return undefined;
    },
  },
  methods: {
    async handleDeleteTrack() {
      const isValid = await this.$refs.deleteConfirm.validate();
      if (!isValid || this.deletePending) return;
      this.deletePending = true;
      try {
        await apiClient.delete(
          `/competences/${this.competence.id}/tracks/${this.track.id}`
        );
        this.$emit("onUpdateTracks");
        this.$toast(`Трек ${this.track?.track.name} удален из компетенции.`, {
          type: "success",
        });
        this.$router.push({
          name: "competence_tracks",
          params: {
            id: this.$route.params.id,
          },
        });
      } catch (error) {
        this.$toast(`Не удалось удалить трек. ${error.message}`, {
          type: "error",
        });
      }
      this.nameConfirm = "";
      this.$refs.deleteConfirm.reset();
    },
  },
};
</script>
