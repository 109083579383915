<template>
  <div>
    <h3 class="text-h5 mb-0"><strong>Языки программирования</strong></h3>
    <div class="text-caption mb-3">
      Добавить язык программирования можно в
      <a :href="talentAdminURL" target="_blank" rel="noopener noreferrer"
        >админке Таланта</a
      >
    </div>
    <v-alert v-if="!gitRepo.length && !pending" color="red" dense type="error">
      Добавьте языки программирования
    </v-alert>
    <div class="mb-5">
      <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
        <div class="d-flex">
          <validation-provider
            rules="required"
            tag="div"
            class="flex-grow-1 mr-3"
          >
            <v-select
              v-model="selectedLang"
              :items="langOptions"
              label="Выбрать язык"
              dense
              outlined
            >
            </v-select>
          </validation-provider>
          <v-btn
            color="primary"
            type="submit"
            :loading="pending"
            :disabled="!selectedLang"
            >Добавить</v-btn
          >
        </div>
      </validation-observer>
      <!-- <v-checkbox
        v-for="item in langOptions"
        :key="item.value"
        v-model="selectedLangs"
        :label="item.text"
        color="primary"
        :value="item.value"
        hide-details
      ></v-checkbox> -->
    </div>
    <v-simple-table v-if="gitRepo.length" class="mb-3">
      <template #default>
        <thead>
          <tr>
            <th>Язык программирования</th>
            <th width="100px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in gitRepo" :key="item.id">
            <td>{{ item.language.name }}</td>
            <td class="text-right">
              <v-btn title="Удалить" icon @click="handleDeleteLang(item)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <app-pager
      :value="page"
      :total="total"
      :limit="limit"
      @input="getGitRepo"
    ></app-pager>
    <div v-if="error" class="error--text my-3">
      Не удалось получить настройки репозиториев. {{ error }}
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import { pathJoin } from "@/api/utils";
export default {
  name: "GitSection",
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gitRepo: [],
      pending: true,
      error: "",
      selectedLang: "",
      page: 1,
      limit: 30,
      total: 0,
    };
  },
  computed: {
    langOptions() {
      const { gitRepo } = this;
      const selectedIds = gitRepo.map((n) => n.language_id);
      return this.$store.state.talent.langs.map((n) => ({
        text: n.name,
        value: n.id,
        disabled: selectedIds.includes(n.id),
      }));
    },
    talentAdminURL() {
      return pathJoin(
        process.env.VUE_APP_TALENT_HOST,
        "/admin/package/programminglanguage/"
      );
    },
  },
  created() {
    this.getLangs();
    this.getGitRepo(1);
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid || this.pending) return;
      const { selectedLang } = this;
      this.pending = true;
      try {
        await apiClient({
          method: "POST",
          url: `/tracks/${this.track.id}/settings/git_repo/languages`,
          data: {
            languages_ids: [selectedLang],
          },
        });
        this.getGitRepo();
        this.$toast(`Язык программирования добавлен к настройкам трека`, {
          type: "success",
        });
        this.selectedLang = "";
        this.$refs.form.reset();
      } catch (error) {
        this.$toast(
          `Не удалось добавить язык программирования. ${error.message}`,
          { type: "error" }
        );
      }
      this.pending = false;
    },
    async getLangs() {
      try {
        await this.$store.dispatch("talent/getAllLanguages");
      } catch (error) {
        this.$toast(
          `Не удалось получить языки программирования из API Таланта. ${error.message}`,
          { type: "error" }
        );
      }
    },
    async getGitRepo(page = 1) {
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/tracks/${this.track.id}/settings/git_repo/languages`,
          params: {
            limit: this.limit,
            offset: (page - 1) * this.limit,
          },
        });
        this.gitRepo = data.results;
        this.total = data.count;
        this.page = page;
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    async handleDeleteLang(gitRepo) {
      const confirm = await this.$root.$confirm(
        "Удалить настройку мероприятия",
        `Вы уверены что хотите удалить язык программирования <strong>${gitRepo.language.name}</strong> из этого трека?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (!confirm) return;
      try {
        await apiClient.delete(
          `/tracks/${this.track.id}/settings/git_repo/languages/${gitRepo.id}`
        );
        this.getGitRepo(1);
        this.$toast("Язык программирования удален", { type: "success" });
      } catch (error) {
        this.$toast(
          `Не удалось удалить язык программирования. ${error.message}`,
          { type: "error" }
        );
      }
    },
  },
};
</script>

<style></style>
